import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/styles.js';

import fhouse from '../assets/img/fh/fh-01.jpeg';
import collage from '../assets/img/collage.jpg';

const Home = () => {
  return (
    <div>
      <div className="carousel slide" id="HeroSlider" data-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active"><img className="d-block" src={fhouse} alt="" style={{ width: '100%' }} /></div>
        </div>
      </div>
      <section className="page-content">
        <div className="container-fluid with-marginal">
          <div className="row">
            <div className="col">
              <section className="section-intro bg-light">
                <div className="container-fluid wrapper">
                  <h1>Herzlich Willkommen im Alten Land</h1>
                  <p></p>
                </div>
              </section>
              <section className="gallery-grid result-list">
                <div className="container-fluid wrapper">

                  <div className="row gallery-grid-results list-view with-marginal">

                    <div className="result-box">
                      <div className="card" id="box-20675" style={{width:'100%'}}><Link to="/ferienhaus/">
                        <div className="img-frame"><img className="card-img" src={fhouse} alt="Ferienhaus Erste Meile" style={{ width: '100%' }} /></div>
                        <div className="card-body text-left">
                          <h3>Ferienhaus Erste Meile</h3>
                          <h4>Hollern-Twielenfleth</h4>
                          <div className="card-body-list-view">
                            <dl>
                            </dl>
                          </div>
                          <p className="card-teasertext">Erholung &uuml;ber 2 Etagen, f&uuml;r maximal 4 Personen</p>
                          <p className="card-longtext"></p>
                          <p className="card-button text-right">
                            <button className="btn btn-sm btn-primary" onClick={() => { window.location.href = '/ferienhaus'; }}> <i className="fas fa-external-link-alt"> </i> Detailansicht</button>
                          </p>
                        </div>
                        <div className="card-cover"><i className="fas fa-search-plus"></i></div>
                        <div className="card-ribbon"><span className="card-ribbon-text"><i className="fas fa-users"></i> 4</span><span className="card-ribbon-text"><i className="fas fa-bed"></i> 4</span><span className="card-ribbon-text"><i className="fas fa-bath"></i> 2</span><span className="card-ribbon-text"><i className="fas fa-car"></i> 2</span></div></Link></div>
                    </div>
                  </div>
                  <div className="row gallery-grid-results list-view with-marginal">

                    <div className="result-box">
                      <div className="card" id="box-20675" style={{width:'100%'}}><Link to="/alteland">
                        <div className="img-frame"><img className="card-img" src={collage} alt="Das alte Land" style={{ width: '100%' }} /></div>
                        <div className="card-body text-left">
                          <h3>Das Alte Land</h3>
                          <h4>(Dat ole Land)</h4>
                          <div className="card-body-list-view">
                            <dl>

                            </dl>
                          </div>
                          <p className="card-teasertext">S&uuml;dlich der Elbe in der Elbmarsch liegen die drei Meilen des Alten Landes zwischen den Fl&uuml;ssen Schwinge und der S&uuml;derelbe.</p>
                          <p className="card-longtext"></p>
                          <p className="card-button text-right">
                            <button className="btn btn-sm btn-primary" onClick={() => { window.location.href = '/alteland'; }}> <i className="fas fa-external-link-alt"> </i> Detailansicht</button>
                          </p>
                        </div>
                        <div className="card-cover"><i className="fas fa-search-plus"></i></div>
                        <div className="card-ribbon"><span className="card-ribbon-text"><i className="fas fa-apple-alt"></i></span></div></Link></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
