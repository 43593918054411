import React from 'react';
import '../../styles/styles.js';
import FerienhausTabBelegung from './FerienhausTabsBelegung.js';
import FerienhausTabsPreise from './FerienhausTabsPreise.js';
import FerienhausTabsAGB from './FerienhausTabsAGB.js';
import FerienhausTabsBeschreibung from './FerienhausTabsBeschreibung.js';

const FerienhausTabs = () => {
  return (
    <div>
      <section className="page-details-tabs">
        <div className="container-fluid wrapper">
          <div className="row">
            <div className="detail-box">
              <ul className="nav nav-tabs" id="DetailsTabs" role="tablist">
                <li className="nav-item"><a className="nav-link active" id="belegung-tab" data-toggle="tab" href="#belegung" role="tab" aria-controls="belegung" aria-selected="true">Belegung</a></li>
                <li className="nav-item"><a className="nav-link" id="beschreibung-tab" data-toggle="tab" href="#beschreibung" role="tab" aria-controls="beschreibung" aria-selected="true">Beschreibung</a></li>
                <li className="nav-item"><a className="nav-link" id="hausinfo-tab" data-toggle="tab" href="#hausinfo" role="tab" aria-controls="hausinfo" aria-selected="false">Mietbedingungen</a></li>
                <li className="nav-item"><a className="nav-link" id="preise-tab" data-toggle="tab" href="#preise" role="tab" aria-controls="preise" aria-selected="false">Preise</a></li>
              </ul>
              <div className="tab-content" id="DetailsTabsContent">
                <FerienhausTabBelegung />
                <FerienhausTabsBeschreibung />
                <FerienhausTabsAGB />
                <FerienhausTabsPreise />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div >
  );
};

export default FerienhausTabs;
