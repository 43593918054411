import React from 'react';
import HeroImage from './HeroImage';
import FerienhausGallery from './common/FerienhausGallery.js';
import FerienhausTabs from './common/FerienhausTabs.js';
import '../styles/styles.js';

const Ferienhaus = () => {
  return (
    <div>
      <HeroImage />
      <section className="page-details-header">
        <div className="container-fluid wrapper">
          <div className="row">
            <div className="col">
              <h1>Ferienhaus Erste Meile</h1>
            </div>
          </div>
        </div>
      </section>
      <FerienhausGallery />
      <FerienhausTabs />
    </div>
  );
};

export default Ferienhaus;
