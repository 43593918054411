import React from 'react';
import HeroImage from './HeroImage';
import FerienhausTeaser from './FerienhausTeaser.js';
import '../styles/styles.js';

const Impressum = () => {
  return (
    <div>
      <HeroImage />
      <section className="page-content">
        <div className="container-fluid with-marginal">
          <div className="row">
            <div className="col">
              <section className="section-intro bg-light">
                <div className="container-fluid wrapper">
                  <h1>Impressum</h1>
                  <p>Ferienhaus Erste Meile<br />
                    Martina + Claus Bardenhagen<br />
                    Hinterstrasse 16a<br />
                    21723 Hollern-Twielenfleth<br />
                    Deutschland<br />
                    <br />
                    fh.bardenhagen@gmx.de<br />
                    +49 4141 70305<br />
                    +49 160 1780450<br /></p>
                </div>
              </section>
            </div>
            <FerienhausTeaser />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Impressum;
