const API_URL_K = 'https://ferienhaus-erste-meile.de/kontakt.php';
const API_URL_A = 'https://ferienhaus-erste-meile.de/anfrage.php';
const API_KEY_K = 'cf83e1357eefb8bdf1542850d66d8007d620e4050b5715dc83f4a921d36ce9ce47d0d13c5d85f2b0ff8318d2877eec2f63b931bd47417a81a538327af927da3e';
const API_KEY_A = '967b62c66823bfbeada99234bfdb2548339e59c95a691fdceb554f24a4db81452c311bfa8de4f5af8530d05faff2d05236ab25d3e9383c1c129b8a048b72470e';

export async function sendFormData(formData, formType) {
    try {
        const API_KEY = formType ? API_KEY_K : API_KEY_A;
        const API_URL = formType ? API_URL_K : API_URL_A;

        const response = await fetch(API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': API_KEY,
            },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            console.error('Failed to send form data:', response);
            throw new Error('Failed to send form data');
        }

        return await response.json();
    } catch (error) {
        console.error('Error sending form data:', error);
        throw error;
    }
}
