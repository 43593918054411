import React from 'react';
import '../styles/styles.js';

const Map = () => {
  return (
    <section className="google-maps">
      <iframe title="mapFHEM" width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=9.557590484619142%2C53.59183459029395%2C9.560578465461731%2C53.59321322347219&amp;layer=mapnik&amp;marker=53.59252391250575%2C9.559084475040436" style={{border: '1px solid black'}}></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=53.59252&amp;mlon=9.55908#map=19/53.59252/9.55908">Größere Karte anzeigen</a></small>
    </section>
  );
};

export default Map;
