import React from 'react';
import '../../styles/styles.js';
import TraumfewoCalendar from './TraumfewoCalendar.js';

const FerienhausTabBelegung = () => {
  return (
    <div className="tab-pane fade show active" id="belegung" role="tabpanel" aria-labelledby="belegung-tab">
      <div className="wrapper">
        <TraumfewoCalendar />
      </div>
    </div>
  );
};

export default FerienhausTabBelegung;
