import React from 'react';
import '../../styles/styles.js';

const FerienhausTabsAGB = () => {
  return (
    <div className="tab-pane fade" id="hausinfo" role="tabpanel" aria-labelledby="hausinfo-tab">
      <div className="wrapper">
        <p>
          <br />
          <b>1.	Vertragsabschluss</b><br />
          <br />
          Der Mietvertrag über das anliegend beschriebene Ferienhaus ist verbindlich geschlossen, wenn der in der Anlage beigefügte Mietvertrag vom Mieter unterschrieben dem Vermieter zugegangen ist.<br />
          <br />
          <br />
          <b>2. 	Mietpreis und Nebenkosten</b><br />
          <br />
          In dem vereinbarten Mietpreis sind alle pauschal berechneten Nebenkosten (z.B. für Strom, Heizung, Wasser) enthalten. Haben die Vertragsparteien ausdrücklich eine verbrauchsabhängige Abrechnung oder Zusatzleistungen vereinbart (z.B. Bettwäsche,  Endreinigung), deren Inanspruchnahme dem Mieter freigestellt sind, sind diese Nebenkosten gesondert in Rechnung zu stellen.<br />
          Wurde eine Anzahlung von 10% des Gesamtpreises vereinbart, ist diese bei Vertragsschluss fällig.<br />
          Der Mieter muss Besucher oder weitere Mitbewohner anmelden und gegebenenfalls einen Aufpreis an den Vermieter zahlen. Übernachtungsgäste bedürfen grundsätzlich der Erlaubnis des Vermieters. <br />
          <br />
          <br />
          <b>3. 	Kaution</b><br />
          <br />
          Haben die Vertragsparteien eine Kaution vereinbart, zahlt der Mieter an den Vermieter eine Sicherheit für überlassene Einrichtungs- und Ausstattungsgegenstände. Die Kaution ist zusammen mit der Restzahlung zu leisten und ist nicht verzinslich. Sie wird bei Abreise, oder spätestens innerhalb von 14 Tagen nach Beendigung des Mietverhältnisses an den Mieter zurückerstattet. <br />
          <br />
          <br />
          <b>4. 	Anreise/ Abreise</b><br />
          <br />
          Bei Anreise sollte eine Uhrzeit verabredet sein. Der Mieter muss eventuell Mängel sofort, oder am nächsten Tag mitteilen.<br />
          Am Abreisetag wird der Mieter das Mietobjekt dem Vermieter bis spätestens 10.00 Uhr geräumt in besenreinem Zustand übergeben.<br />
          <b>Dabei hat der Mieter noch folgende Arbeiten selbst zu erledigen: Abziehen der Bettwäsche, Spülen des Geschirrs und Entleeren der Papierkörbe und Mülleimer.</b><br />
          <br />
          <br />
          <b>5. 	R&uuml;cktritt durch den Mieter</b><br />
          <br />
          Der Mieter kann vor Beginn der Mietzeit durch schriftliche Erklärung gegenüber dem Vermieter vom Mietvertrag zurücktreten. Maßgeblich ist der Zeitpunkt des Zugangs der Rücktrittserklärung beim Vermieter.<br />
          Tritt der Mieter vom Mietvertrag zurück, so hat er pauschalen Ersatz für die beim Vermieter bereits entstandenen Aufwendungen und den entgangenen Gewinn in der nachfolgenden Höhe zu leisten:<br />
          &nbsp;&nbsp;&nbsp;Rücktritt bis zum 45. Tag vor Beginn der Mietzeit:<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>20 % (mindestens jedoch 25 EURO)</b><br />
          &nbsp;&nbsp;&nbsp;Rücktritt bis zum 35. Tag vor Beginn der Mietzeit:<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>50%</b><br />
          &nbsp;&nbsp;&nbsp;danach und bei Nichterscheinen<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>80 %-90%</b><br />
          Dem Mieter bleibt der Nachweis vorbehalten, dass bei dem  Vermieter kein oder ein wesentlich geringerer Schaden entstanden ist.<br />
          Der Mieter kann bei Rücktritt vom Vertrag einen Ersatzmieter benennen, der bereit ist, an seiner Stelle in das bestehende Vertragsverhältnis einzutreten. Der Vermieter kann dem Eintritt des Dritten widersprechen, wenn dieser wirtschaftlich oder persönlich unzuverlässig erscheint.<br />
          Tritt ein Dritter in den Mietvertrag ein, so haften er und der bisherige Mieter dem Vermieter als Gesamtschuldner für den  Mietpreis und die durch den Eintritt des Dritten entstehenden Mehrkosten. Der Vermieter hat nach Treu und Glauben eine nicht in Anspruch genommene Unterkunft anderweitig zu vermieten und muss sich das dadurch Ersparte auf die von ihm geltend gemachten Stornogebühren anrechnen lassen. <br />
          Der Abschluss einer Reiserücktrittsversicherung wird dem Mieter empfohlen.
          <br />
          <br />
          <b>6. 	Kündigung durch den Vermieter</b><br />
          <br />
          Der Vermieter kann das Vertragsverhältnis vor oder nach Beginn der Mietzeit ohne Einhaltung einer Frist kündigen, wenn der Mieter trotz vorheriger Mahnung die vereinbarten Zahlungen (Anzahlung, Restzahlung und Kaution) nicht fristgemäß leistet oder sich ansonsten in einem solchen Maße vertragswidrig verhält, dass dem Vermieter eine Fortsetzung des Vertragsverhältnisses nicht  zuzumuten ist. In diesem Falle kann der Vermieter von dem Mieter Ersatz der bis zur Kündigung entstandenen Aufwendungen und des entgangenen Gewinns verlangen.<br />
          <br />
          <br />
          <b>7. 	Aufhebung des Vertrags wegen außergewöhnlicher Umstände</b><br />
          <br />
          Der Mietvertrag kann von beiden Seiten gekündigt werden, wenn die Erfüllung des Vertrages infolge bei Vertragsabschluss nicht vorhersehbarer höherer Gewalt erheblich erschwert, gefährdet oder beeinträchtigt wird. Beide Vertragsparteien werden von ihren vertraglichen Verpflichtungen frei. Sie müssen jedoch der jeweils anderen Vertragspartei bereits erbrachte Leistungen erstatten.<br />
          <br />
          <br />
          <b>8. 	Pflichten des Mieters</b><br />
          <br />
          Der Mieter verpflichtet sich, das Mietobjekt mitsamt Inventar mit aller Sorgfalt zu behandeln. Für die schuldhafte Beschädigung von Einrichtungsgegenständen, Mieträumen oder des Gebäudes sowie  der zu den Mieträumen oder dem Gebäude gehörenden Anlagen ist der Mieter ersatzpflichtig, wenn und insoweit sie von ihm oder seinen Begleitpersonen oder Besuchern schuldhaft verursacht worden ist. In den Mieträumen entstehende Schäden hat der Mieter soweit er  nicht selbst zur Beseitigung verpflichtet ist, unverzüglich dem Vermieter anzuzeigen. Für die durch nicht rechtzeitige Anzeige  verursachten Folgeschäden ist der Mieter ersatzpflichtig. In Spülsteine, Ausgussbecken und Toilette dürfen Abfälle, Asche, schädliche Flüssigkeiten und ähnliches nicht hineingeworfen oder gegossen werden. Treten wegen Nichtbeachtung dieser Bestimmungen Verstopfungen in den Abwasserrohren auf, so trägt der Verursacher die Kosten der Instandsetzung. Bei eventuell auftretenden Störungen an Anlagen und Einrichtungen des Mietobjektes ist der Mieter verpflichtet, selbst alles Zumutbare zu tun, um zu einer Behebung der Störung beizutragen oder evtl. entstehenden Schaden gering zu halten.<br />
          Der Mieter ist verpflichtet, den Vermieter oder ggf. die Hausverwaltung über Mängel der Mietsache unverzüglich zu unterrichten. Unterlässt der Mieter diese Meldung, so stehen ihm keine Ansprüche wegen Nichterfüllung der vertragsmäßigen Leistungen (insbesondere keine Ansprüche auf Mietminderung) zu.<br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default FerienhausTabsAGB;
