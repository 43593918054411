import React from 'react';
import HeroImage from './HeroImage';
import FerienhausTeaser from './FerienhausTeaser';
import '../styles/styles.js';

import schafe from '../assets/img/schafe.jpg';
import fachwerkhaus from '../assets/img/fachwerkhaus.jpg';

const AlteLand = () => {
  return (
    <div>
      <HeroImage />
      <section className="page-content">
        <div className="container-fluid with-marginal">
          <div className="row">
            <div className="col">
              <section className="section-intro bg-light">
                <div className="container-fluid wrapper">
                  <h1>Dat ole Land</h1>
                  <br />
                    <section class="hero-img"><img src={schafe} alt="" style={{width:'100%'}} /></section>
                    <br />
                      <h3>Geschichte</h3>
                      <p>
                        Der Name Altes Land weist auf die Besiedlungsgeschichte hin. Auf Plattdeutsch heißt das Gebiet Olland (hochdeutsch „Altland“). Dieser Name war ursprünglich Gegenbegriff zu Neuland und bezeichnete das schon länger entwässerte und bebaute Land im Gegensatz zum neu gewonnenen. Er erinnert damit an die Kolonisierung durch niederländische Kolonisten zwischen 1130 und 1230. Auch der Name der Altländer Gemeinde Hollern geht auf die Holländer zurück.<br />
                          Das Alte Land, das zum Erzstift Bremen gehörte, verfügte seit dem Mittelalter über eine Selbstverwaltung, zuletzt seit 1885 in Gestalt des preußischen Landkreises Jork, der neben dem Alten Land auch die Stadt Buxtehude und die Gemeinde Neuland umfasste. Die Auflösung des Landkreises 1932 bedeutete das Ende dieser Selbstverwaltung; der Teil westlich der Este wurde dem Landkreis Stade, der östlich davon dem Landkreis Harburg angeschlossen. Durch das Groß-Hamburg-Gesetz wurden 1937 die Gemeinden Cranz, Neuenfelde und Francop Hamburg angegliedert und 1938 eingemeindet. 1972 wurden die Orte Hove und Moorende aus dem Landkreis Harburg der Gemeinde Jork angeschlossen und gehören seitdem zum Landkreis Stade. Als einziger Teil des Alten Landes verblieb die Ortschaft Rübke – heute Teil der Gemeinde Neu Wulmstorf – beim Landkreis Harburg.
                      </p>
                      <h3>Gliederung</h3>
                      <p>
                        Das Alte Land ist entlang des Elbufers in drei „Meilen“ gegliedert, die zugleich die Besiedlungsgeschichte widerspiegeln: Die Erste Meile, zwischen den Flüssen Schwinge und Lühe, wurde um 1140 eingedeicht und besiedelt. Die Zweite Meile umfasst das östlich davon gelegene Gebiet zwischen Lühe und Este, dessen Eindeichung Ende des 12. Jahrhunderts abgeschlossen war. Die Eindeichung der Dritten Meile zwischen Este und Süderelbe wurde erst Ende des 15. Jahrhunderts abgeschlossen, da das Gebiet besonders stark durch Sturmfluten gefährdet und betroffen war. Das Alte Land ist das größte geschlossene Obstanbaugebiet Europas.
                      </p>
                      <h3>Obstanbau</h3>
                      <p>
                        Erstmals schriftlich erwähnt wird der Obstanbau an der Niederelbe im Stadtbuch von Stade, wo am 25. März 1312 von einem innerhalb der Stadt gelegenen Pomarium (Obstgarten) der Herren des Klosters Sankt Georg die Rede ist. Im 17. Jahrhundert wurde bereits auf 200 Hektar Obst angebaut. In der zweiten Hälfte des 19. Jahrhunderts entwickelte sich der Obstbau zur dominierenden Nutzung im Raum und beherrscht somit seit über 150 Jahren das Gebiet. Heute reifen auf 10.700 Hektar Äpfel, Kirschen, Birnen und anderes Obst. 77 Prozent der Obstbäume im Obstbaugebiet Altes Land sind Äpfel und 12,7 Prozent Kirschen.
                      </p>
                      <br />
                        <br />
                          <p><i>
                            Wikipedia (2023): Altes Land, [online] https://de.wikipedia.org/wiki/Altes_Land [11.10.2023].
                          </i></p>
                          <br />
                            <section class="hero-img"><img src={fachwerkhaus} alt="" style={{width:'100%'}} /></section>
                </div>
              </section>
            </div>
            <FerienhausTeaser />
          </div>
        </div>
      </section>
    </div>
  );
};

            export default AlteLand;
