import React from 'react';
import HeroImage from './HeroImage';
import FerienhausTeaser from './FerienhausTeaser';
import '../styles/styles.js';

const Datenschutz = () => {
  return (
    <div>
      <HeroImage />
      <section className="page-content">
        <div className="container-fluid with-marginal">
          <div className="row">
            <div className="col">
              <section className="section-intro bg-light">
                <div className="container-fluid wrapper">
                  <h1>Datenschutzerkl&auml;rung</h1>
                  <p>Datenschutz<br />
                    <br />
                    1 Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br />
                    <br />
                    2 Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.<br />
                    <br />
                    3 Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br />
                  </p>
                </div>
              </section>
            </div>
            <FerienhausTeaser />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Datenschutz;
