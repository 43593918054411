import React, { useState, useRef, useEffect, useCallback } from 'react';
import DOMPurify from 'dompurify';
import { sendFormData } from '../services/api';
import HeroImage from './HeroImage.js';
import FerienhausTeaser from './FerienhausTeaser.js';
import '../styles/styles.js';

import messageOK from '../assets/img/logo-trans.png';

function Kontakt() {
  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    lname: '',
    fname: '',
    telefon: '',
    email: '',
    nachricht: ''
  });

  const [formValid, setFormValid] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleReset = () => {
    formRef.current.reset();
  };

  const validateForm =  useCallback(() => {
    const { lname, fname, telefon, email, nachricht } = formData;
    const isEmailValid = validateEmail(email);
    const isTelefonValid = validateTelefon(telefon);
    console.log(isEmailValid);
    console.log(isTelefonValid);
    return lname && fname && isTelefonValid && isEmailValid && nachricht;
  }, [formData]);

  useEffect(() => {
    // Überprüfe, ob alle erforderlichen Felder ausgefüllt sind und valide Eingaben haben
    const formValid = validateForm();
    setFormValid(formValid);
  }, [formData, validateForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateTelefon = (telefon) => {
    const telefonRegex = /^(?:\+49|0|(?:\+\d{1,3})|\d{1,4})(?:[\s/-]?\d{1,4}){1,3}$/;
    return telefonRegex.test(telefon);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Bereinige die Eingabedaten
    const sanitizedData = {
      lname: DOMPurify.sanitize(formData.lname),
      fname: DOMPurify.sanitize(formData.fname),
      telefon: DOMPurify.sanitize(formData.telefon),
      email: DOMPurify.sanitize(formData.email),
      nachricht: DOMPurify.sanitize(formData.nachricht),
    };

    if (formValid) {
    try {
      const responseData = await sendFormData(sanitizedData, true);
      if (responseData.message === 'Erfolgreich versendet') {
        setShowSuccessMessage(true);
      } else {
        console.error(responseData);
        alert('Es ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut oder kontaktieren Sie uns telefonisch. Vielen Dank.');
      }
      setFormData({
        lname: '',
        fname: '',
        telefon: '',
        email: '',
        nachricht: ''
      });
    } catch (error) {
      console.error('Fehler beim Senden des Formulars:', error);
      alert('Es ist ein Fehler aufgetreten! Bitte versuchen Sie es später erneut oder kontaktieren Sie uns telefonisch. Vielen Dank.');
    }
  }
  };

  return (
    <div>
      <HeroImage />
      <section className="page-content">
        <div className="container-fluid with-marginal">
          <div className="row">
            <div className="col">
              <section className="section-intro bg-light">
                <div className="container-fluid wrapper">
                  {showSuccessMessage ?
                    <div>
                      <h1>Vielen Dank</h1>
                      <br /><br />
                      <p>Ihre Anfrage wurde erfolgreich &uuml;bermittelt.</p>
                      <p>Sobald wir diese gepr&uuml;ft haben, geben wir Ihnen R&uuml;ckmeldung.</p>
                      <br />
                      <section className="hero-img"><img src={messageOK} title="logo" alt="" style={{ width: '30%' }} /></section>
                      <br />
                    </div>
                    :
                    <div>
                      <h1>Kontakt</h1>
                      <br />
                      <form className="form-horizontal request " id="kontaktform" onSubmit={handleSubmit} style={{ marginTop: 0 }} ref={formRef}>
                        <div className="form-group" >
                          <label htmlFor="mailName" className="col-sm-2 control-label obligation">
                            Name<var id="lname"></var></label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" name="lname" value={formData.lname} onChange={handleChange} id="lname" required />
                          </div>
                        </div>
                        <div className="form-group" >
                          <label htmlFor="mailNameV" className="col-sm-2 control-label obligation">
                            Vorname<var id="fname"></var></label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" name="fname" value={formData.fname} onChange={handleChange} id="fname" required />
                          </div>
                        </div>
                        <div className="form-group" >
                          <label htmlFor="mailAdress" className="col-sm-2 control-label obligation">
                            Email<var id="email"></var></label>
                          <div className="col-sm-10">
                            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} id="email" pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" required />
                          </div>
                        </div>
                        <div className="form-group" >
                          <label htmlFor="mailPhone" className="col-sm-2 control-label ">
                            Telefon<var id="telefon"></var></label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" name="telefon" value={formData.telefon} onChange={handleChange} id="telefon" pattern="^(?:\+49|0|(?:\+\d{1,3})|\d{1,4})(?:[\s\/-]?\d{1,4}){1,3}$" required />
                          </div>
                        </div>
                        <div className="form-group" >
                          <label htmlFor="mailNotice" className="col-sm-2 control-label">
                            Nachricht<var id="nachricht"></var></label>
                          <div className="col-sm-10">
                            <textarea name="nachricht" id="nachricht" className="form-control" rows="3" value={formData.nachricht} onChange={handleChange} required></textarea>
                          </div>
                        </div>
                        <div className="form-group spacer" >
                          <div className="col-md-offset-2 col-md-10">
                            <button type="submit" id="senden" className="btn btn-primary" disabled={!formValid}>Abschicken</button>
                            <button type="button" className="btn btn-default" onClick={handleReset}>Zur&uuml;cksetzen</button>
                            <input type="hidden" name="step" value="1" />
                            <br />
                            <br />
                            <p>Bitte f&uuml;llen Sie alle markierten Felder aus, damit wir Ihre Anfrage bearbeiten und Ihnen R&uuml;ckmeldung geben k&ouml;nnen.</p>
                          </div>
                        </div>
                      </form>
                    </div>
                  }
                </div>
              </section>
            </div>
            <FerienhausTeaser />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Kontakt;
