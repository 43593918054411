import React from 'react';
import '../styles/styles.js';

import teaserImage from '../assets/img/fh/fh-02.jpeg';

const FerienhausTeaser = () => {
  return (
    <aside className="col-12 col-xs-12 col-lg-3">
      <div className="container-fluid wrapper">
        <div className="card contact-person">
          <img className="card-img-top" src={teaserImage} alt="Familie Bardenhagen" />
          <div className="card-body">
            <h5 className="card-title">Ferienhaus Erste Meile</h5>
            <p>Familie Bardenhagen</p>
            <ul className="list-group list-group-flush contact-info">
              <li className="list-group-item"><span><i className="fa fa-facebook"></i><a  href="https://www.facebook.com/ferienhauserstemeile">Facebook</a></span></li>
              <li className="list-group-item"><span><i className="fa fa-instagram"></i><a  href="https://www.instagram.com/ferienhauserstemeile">Instagram</a></span></li>
              <li className="list-group-item"><span><i className="fa fa-phone"></i>Telefon: </span>+49 4141 70305</li>
              <li className="list-group-item"><span><i className="fa fa-envelope"></i>Schreiben Sie uns: </span><a className="contact-form" href="/kontakt/">zum Kontaktformular</a></li>
              <li className="list-group-item"><span><i className="fa fa-vcard"></i>Postanschrift: </span>
                <ul>
                  <li>Hinterstrasse 16a<br />21723 Hollern-Twielenfleth</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default FerienhausTeaser;
