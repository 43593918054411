import React, { useEffect } from 'react';

const TraumfewoCalendar = () => {
  useEffect(() => {
    const loadScript = (src, id) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        script.type = 'application/javascript';
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Script load error for ${src}`));
        document.body.appendChild(script);
      });
    };

    const initializeCalendar = () => {
      const createWidget469966 = () => {
        const calendarNode = document.getElementById('traumfewo-calendar-469966');
        if (window.tfw && window.tfw.CalendarWidget) {
          const calendarWidget = new window.tfw.CalendarWidget(calendarNode);
          calendarWidget.throttle("resize", "optimizedResize");
          window.addEventListener('resize', () => calendarWidget.update(), false);
          calendarWidget.init();
        }
      };

      // Load the calendar widget script and initialize the widget
      loadScript('https://static2.traum-ferienwohnungen.de/vmb-public/cache/js/calendarWidget.js', 'calendar-widget-script')
        .then(() => {
          if (window.tfw && window.tfw.CalendarWidget) {
            createWidget469966();
          } else {
            window.addEventListener('load', createWidget469966);
          }
        })
        .catch(error => {
          console.error('Error loading script:', error);
        });
    };

    initializeCalendar();

    // Cleanup function to remove the script when component unmounts
    return () => {
      const script = document.getElementById('calendar-widget-script');
      if (script) {
        document.body.removeChild(script);
      }
      window.removeEventListener('load', initializeCalendar);
    };
  }, []);

  return (
    <div id='traumfewo-calendar-469966' className="tfw-calendar-app"
         data-base_url='www.traum-ferienwohnungen.de'
         data-language_id="1"
         data-startMonth='0'
         data-numberOfMonths='12'
         data-listingid='469966'>
      <a id='calendar-link-469966' href='https://www.traum-ferienwohnungen.de/469966'>
        Ferienhaus Erste Meile auf www.traum-ferienwohnungen.de
      </a>
    </div>
  );
};

export default TraumfewoCalendar;
