import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/styles.js';

const Header = () => {
  const [activeLink, setActiveLink] = useState(null);

  // Funktion zum Festlegen des aktiven Links basierend auf dem aktuellen Pfad
  const setActive = (path) => {
    setActiveLink(path);
  };

  return (
    <header className="page-header sticky-top">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg">
          <a className="navbar-brand" href="/">Ferienhaus erste Meile</a>
          <button className="navbar-hamburger" type="button" data-toggle="collapse" data-target="#MainMenu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-hamburger-icon"></span>
            <span className="navbar-hamburger-icon"></span>
            <span className="navbar-hamburger-icon"></span>
            <span className="navbar-hamburger-icon"> </span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="MainMenu">
            <ul className="navbar-nav">
              <li className={activeLink === '/' ? 'nav-item active' : 'nav-item '}><Link onClick={() => setActive('/')} className="nav-link" to="/">Home<span className="sr-only">(current)</span></Link></li>
              <li className={activeLink === '/ferienhaus' ? 'nav-item active' : 'nav-item '}><Link onClick={() => setActive('/ferienhaus')} className="nav-link" to="/ferienhaus">Ferienhaus</Link></li>
              <li className={activeLink === '/belegung' ? 'nav-item active' : 'nav-item '}><Link onClick={() => setActive('/belegung')} className="nav-link" to="/belegung">Belegung</Link></li>
              <li className={activeLink === '/anfrage' ? 'nav-item active' : 'nav-item '}><Link onClick={() => setActive('/anfrage')} className="nav-link" style={{color: '#fff', backgroundColor:  '#73a24e', borderColor: '#006699'}} to="/anfrage">Anfrage</Link></li>
              <li className={activeLink === '/kontakt' ? 'nav-item active' : 'nav-item '}><Link onClick={() => setActive('/kontakt')} className="nav-link" to="/kontakt">Kontakt</Link></li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
