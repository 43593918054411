import React, { useState } from 'react';
import '../../styles/styles.js';

const FerienhausTabsPreise = () => {

  const [people, setPeople] = useState(2);
  const [days, setDays] = useState(1);

  const basePrice = 80;
  const extraPersonCost = 15;
  const totalPrice = basePrice * days + (people - 2) * extraPersonCost * days;

  return (
    <div className="tab-pane fade" id="preise" role="tabpanel" aria-labelledby="preise-tab">
      <div className="wrapper">
        <h3>Preise<small> (ab 2 &Uuml;bernachtungen)</small></h3>
        <table style={{ width: '100%' }} className="table table-striped table-responsive-sm preisemd">
          <tr>
            <td valign="top" nowrap="nowrap">Preise für 2 Personen:</td>
            <td valign="top" nowrap="nowrap"></td>
          </tr>
          <tr>
            <td valign="top" nowrap="nowrap"></td>
            <td valign="top" nowrap="nowrap">€ 80,- pro Nacht</td>
          </tr>
          <tr>
            <td valign="top" nowrap="nowrap">jede weitere Person</td>
            <td valign="top" nowrap="nowrap"></td>
          </tr>
          <tr>
            <td valign="top" nowrap="nowrap"></td>
            <td valign="top" nowrap="nowrap">€ 15,- pro Nacht</td>
          </tr>
        </table>
        <div>
          <br />
          <h3>Berechnung des Gesamtpreises</h3>
          <hr />
          <div style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '20px' }}>
            <label style={{ fontWeight: 'normal', display: 'block', marginBottom: '10px' }}>
              Anzahl der Personen: <b>{people}</b>
            </label>
            <input type="range" min="2" max="4" value={people} onChange={(e) => setPeople(parseInt(e.target.value))} style={{ width: '100%', marginBottom: '20px' }} />
            <label style={{ fontWeight: 'normal', display: 'block', marginBottom: '10px' }}>
              Anzahl der Tage: <b>{days}</b>
            </label>
            <input type="range" min="1" max="14" value={days} onChange={(e) => setDays(parseInt(e.target.value))} style={{ width: '100%', marginBottom: '20px' }} />
            <b>Gesamtpreis: {totalPrice} €</b>
          </div>
        </div>
        <br />
        <br />
        <p><b>Preise für Wünsche, die sich hieraus nicht ergeben, bitte telefonisch erfragen.</b></p>
        <br />
        <h3>Allgemeines</h3>
        <br />
        <p>Bettwäsche, Handtücher und Endreinigung inklusive</p>
        <p>WLAN Nutzung frei</p>
        <br />
      </div>
    </div>
  );
};

export default FerienhausTabsPreise;
