import React from 'react';
import HeroImage from './HeroImage';
import '../styles/styles.js';
import TraumfewoCalendar from './common/TraumfewoCalendar.js';

const Belegung = () => {
  return (
    <div>
      <HeroImage />
      <section className="page-content">
        <div className="container-fluid with-marginal">
          <div className="row">
            <div className="col">
              <section className="section-intro bg-light">
                <div className="container-fluid wrapper">
                <h1>der aktuelle Belegungsplan</h1>
                <TraumfewoCalendar />
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Belegung;
