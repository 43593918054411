
import React from 'react';

import img_000 from "../../assets/img/gallery2024/img_000.jpg";
import img_001 from "../../assets/img/gallery2024/img_001.jpeg";
import img_002 from "../../assets/img/gallery2024/img_002.jpg";
import img_003 from "../../assets/img/gallery2024/img_003.jpg";
import img_004 from "../../assets/img/gallery2024/img_004.jpeg";
import img_005 from "../../assets/img/gallery2024/img_005.jpeg";
import img_006 from "../../assets/img/gallery2024/img_006.jpeg";
import img_007 from "../../assets/img/gallery2024/img_007.jpeg";
import img_008 from "../../assets/img/gallery2024/img_008.jpeg";
import img_009 from "../../assets/img/gallery2024/img_009.jpeg";
import img_010 from "../../assets/img/gallery2024/img_010.jpeg";
import img_011 from "../../assets/img/gallery2024/img_011.jpg";
import img_012 from "../../assets/img/gallery2024/img_012.jpeg";
import img_013 from "../../assets/img/gallery2024/img_013.jpg";
import img_014 from "../../assets/img/gallery2024/img_014.jpeg";
import img_015 from "../../assets/img/gallery2024/img_015.jpeg";
import img_016 from "../../assets/img/gallery2024/img_016.jpeg";
import img_017 from "../../assets/img/gallery2024/img_017.jpeg";
import img_018 from "../../assets/img/gallery2024/img_018.jpeg";
import img_019 from "../../assets/img/gallery2024/img_019.jpeg";
import img_020 from "../../assets/img/gallery2024/img_020.jpeg";
import img_021 from "../../assets/img/gallery2024/img_021.jpeg";
import img_022 from "../../assets/img/gallery2024/img_022.jpg";
import img_023 from "../../assets/img/gallery2024/img_023.jpeg";
import img_024 from "../../assets/img/gallery2024/img_024.jpeg";
import img_025 from "../../assets/img/gallery2024/img_025.jpeg";

const bilder = [img_000, img_001, img_002, img_003, img_004, img_005, img_006, img_007, img_008, img_009, img_010, img_011, img_012, img_013, img_014, img_015, img_016, img_017, img_018, img_019, img_020, img_021, img_022, img_023, img_024, img_025];

export const ImageHandler = ({ number }) => {
    return <img className="d-block" src={ bilder[number] } alt="Fotogallery" style={{display: 'inline-block', width: '100%'}} />;
  };

export const ImageHandler1 = ({ number }) => {
    return <img src={bilder[number]} alt="Fotogallery" />;
  };

export const ImageHandler2 = ({ number }) => {
    return <a className="lightbox" href={bilder[number]}><div className="slider-lightbox-trigger"><i className="fas fa-expand"></i></div></a>
};
