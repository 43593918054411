import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import ScrollToTop from './components/common/ScrollToTop.js';
import Footer from './components/Footer';
import Map from './components/Map.js';
import Impressum from './components/Impressum.js';
import Belegung from './components/Belegung.js';
import Datenschutz from './components/Datenschutz.js';
import AlteLand from './components/AlteLand.js';
import Ferienhaus from './components/Ferienhaus.js';
import Kontakt from './components/Kontakt.js';
import Anfrage from './components/Anfrage.js';
import Home from './components/Home.js';
import './styles/styles.js';

const App = () => {
  return (
    <div><HelmetProvider>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Ferienhaus Erste Meile - Urlaub im alten Land</title>
        <link rel="canonical" href="https://www.ferienhaus-bardenhagen.de/" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="title" content="Ferienhaus Erste Meile - Urlaub im alten Land" />
        <meta name="description" content="Ferienhaus Vermietung" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
        <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
          integrity=
          "sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossOrigin="anonymous">
        </script>

        <script src=
          "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
          integrity=
          "sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
          crossOrigin="anonymous">
        </script>

        <script src=
          "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
          integrity=
          "sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
          crossOrigin="anonymous">
        </script> </Helmet></HelmetProvider>
      <div>
        <BrowserRouter>
          <ScrollToTop />
          <Header />
          <main className="page-start">
            <Routes>
              <Route index element={<Home />} />
              <Route path="datenschutz" element={<Datenschutz />} />
              <Route path="alteland" element={<AlteLand />} />
              <Route path="impressum" element={<Impressum />} />
              <Route path="belegung" element={<Belegung />} />
              <Route path="ferienhaus" element={<Ferienhaus />} />
              <Route path="kontakt" element={<Kontakt />} />
              <Route path="anfrage" element={<Anfrage />} />
            </Routes>
            <Map />
          </main>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
