import React from 'react';
import '../../styles/styles.js';

const FerienhausTabsBeschreibung = () => {
  return (
    <div className="tab-pane fade" id="beschreibung" role="tabpanel" aria-labelledby="beschreibung-tab">
      <div className="wrapper">

        <p><b>Allgemeines</b></p>
        65 m² Wohlfühlfläche über zwei Ebenen<br />
        2 Bäder, 2 Schlafzimmer, Wohnküche mit Essbereich<br />
        Parkmöglichkeiten für 1 Auto direkt am Haus<br />
        teilüberdachte Terrasse mit "Altländer Strandkorb"<br />
        abschliessbare Unterstellmöglichkeit für Fahrräder<br />
        keine Tiere<br />
        Nichtraucher<br />

        <p><b>Wohnküche</b></p>
        Esstisch für 4 Personen<br />
        2 Sessel<br />
        gemütliches Sofa<br />
        voll ausgestattete Küche mit Ceranfeld, Backofen, Kühlschrank, Geschirrspüler und Mikrowelle<br />

        <p><b>Schlafzimmer</b></p>
        1 Zimmer mit Doppelbett<br />
        1 Zimmer mit 2 Einzelbetten<br />
        Im Doppelbettzimmer ist ein Zustellbett möglich<br />

        <p><b>Bad</b></p>
        1 Wannenbad im Obergeschoss<br />
        1 Duschbad im Erdgeschoss<br />
        beide Bäder mit WC und Waschbecken<br />
        Fön<br />

        <p><b>Entertainment/Technik</b></p>
        2 Flachbildfernseher (Wohnzimmer und Schlafzimmer)<br />
        SAT-Anlage (digital)<br />
        Radio mit CD<br />

        <p><b>Garten</b></p>
        Gartenmöbel<br />
        Grill<br />

      </div>
    </div>
  );
};

export default FerienhausTabsBeschreibung;
