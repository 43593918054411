import React from 'react';
import '../styles/styles.js';

import hero from '../assets/img/fh/fh-01.jpeg';

const HeroImage = () => {
  return (
    <div>
      <section className="hero-img">
        <img src={hero} alt="Title Banner" style={{width:'100%'}} />
      </section>
      <section className="page-searchform">
        <div className="container-fluid wrapper">
        </div>
      </section>
    </div>
  );
};

export default HeroImage;
