import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/styles.js';

const Footer = () => {
  return (
    <footer className="bg-dark text-white">
      <section className="footer-content">
        <div className="container-fluid wrapper">
          <div className="row">
            <div className="col-lg-3 mb-3">
              <h3>Kontakt</h3>
              <p><b>Ferienhaus Erste Meile</b><br />Hinterstrasse 16a<br />21723 Hollern-Twielenfleth</p>
              <p>Telefon: +49 4141 70305<br /><a href="/kontakt/">Kontakt</a></p>
            </div>
            <div className="col-lg mb-5"></div>
            <div className="col-lg-3">
              <h3>Navigation</h3>
              <ul className="list-group">
                <li><Link  to="/"> &#9658; Home</Link></li>
                <li><Link  to="/ferienhaus"> &#9658; Ferienhaus</Link></li>
                <li><Link  to="/belegung"> &#9658; Belegung</Link></li>
                <li><Link  to="/kontakt"> &#9658; Kontakt</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-legal">  
        <div className="container-fluid p-1 bg-black text white">
          <ul className="list-inline font-weight-light">
            <li><Link  to="/impressum">Impressum</Link></li>
            <li><Link  to="/datenschutz">Datenschutz</Link></li>
            
          </ul>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
