import React from 'react';
import { ImageHandler, ImageHandler1, ImageHandler2 } from './ImageHandler.js';
import '../../styles/styles.js';

const FerienhausGallery = () => {
    return (
        <div>
            <section className="page-details-infobox">
                <div className="container-fluid wrapper">
                    <div className="row">
                        <div className="col-12 col-md-7 col-lg-8 detail-page-slider">
                            <div className="carousel slide" id="DetailFotoslider" data-ride="carousel">
                                <div className="carousel-outer">
                                    <div className="carousel-inner">

                                        <div className="carousel-item active" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="0" /><ImageHandler number="0" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="1" /><ImageHandler number="1" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="2" /><ImageHandler number="2" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="3" /><ImageHandler number="3" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="4" /><ImageHandler number="4" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="5" /><ImageHandler number="5" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="6" /><ImageHandler number="6" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="7" /><ImageHandler number="7" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="8" /><ImageHandler number="8" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="9" /><ImageHandler number="9" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="10" /><ImageHandler number="10" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="11" /><ImageHandler number="11" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="12" /><ImageHandler number="12" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="13" /><ImageHandler number="13" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="14" /><ImageHandler number="14" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="15" /><ImageHandler number="15" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="16" /><ImageHandler number="16" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="17" /><ImageHandler number="17" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="18" /><ImageHandler number="18" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="19" /><ImageHandler number="10" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="20" /><ImageHandler number="20" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="21" /><ImageHandler number="21" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="22" /><ImageHandler number="22" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="23" /><ImageHandler number="22" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="24" /><ImageHandler number="24" /></div>
                                        <div className="carousel-item" style={{ whiteSpace: 'nowrap' }}><ImageHandler2 number="25" /><ImageHandler number="25" /></div>
                                        <a className="carousel-control-prev" href="#DetailFotoslider" role="button" data-slide="prev"><span className="carousel-control-prev-icon" aria-hidden="true"></span><span className="sr-only">Vorheriges</span></a><a className="carousel-control-next" href="#DetailFotoslider" role="button" data-slide="next"><span className="carousel-control-next-icon" aria-hidden="true"></span><span className="sr-only">N&auml;chstes</span></a>
                                    </div>

                                    <ol className="carousel-indicators scrollable mCustomScrollbar">
                                        <li className="active" data-target="#DetailFotoslider" data-slide-to="0"><ImageHandler1 number="0" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="1"><ImageHandler1 number="1" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="2"><ImageHandler1 number="2" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="3"><ImageHandler1 number="3" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="4"><ImageHandler1 number="4" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="5"><ImageHandler1 number="5" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="6"><ImageHandler1 number="6" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="7"><ImageHandler1 number="7" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="8"><ImageHandler1 number="8" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="9"><ImageHandler1 number="9" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="10"><ImageHandler1 number="10" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="11"><ImageHandler1 number="11" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="12"><ImageHandler1 number="12" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="13"><ImageHandler1 number="13" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="14"><ImageHandler1 number="14" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="15"><ImageHandler1 number="15" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="16"><ImageHandler1 number="16" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="17"><ImageHandler1 number="17" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="18"><ImageHandler1 number="18" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="19"><ImageHandler1 number="19" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="20"><ImageHandler1 number="20" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="21"><ImageHandler1 number="21" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="22"><ImageHandler1 number="22" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="23"><ImageHandler1 number="23" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="24"><ImageHandler1 number="24" /></li>
                                        <li data-target="#DetailFotoslider" data-slide-to="25"><ImageHandler1 number="25" /></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-lg-4 detail-page-infobox">


                            <h2>Ferienhaus Erste Meile</h2>
                            <h3>Hollern-Twielenfleth</h3>

                            <dl className="feature-list">

                                <dt>Personen: </dt><dd>4</dd>
                                <dt>Schlafpl&auml;tze: </dt><dd>4</dd>
                                <dt>Etagen: </dt><dd>2</dd>

                                <dt>Wohnfläche: </dt><dd>60 qm</dd>
                                <dt>Parkpl&auml;tze: </dt><dd>2</dd>
                                <dt>Terrasse </dt><dd>&nbsp;</dd>

                                <dt>Nichtraucher </dt><dd>&nbsp;</dd>
                                <dt>keine Tiere </dt><dd>&nbsp;</dd>

                            </dl>

                            <a className="btn btn-primary btn-lg" href="/anfrage">Buchungsanfrage</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FerienhausGallery;
